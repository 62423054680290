<template>
  <v-dialog v-model="showModal" persistent max-width="800">
    <v-card>
      <div class="headerIcon">
        <v-icon class="secondary--text" @click="CloseModalPrev">
          mdi-close
        </v-icon>
      </div>
      <v-card-text>
        <v-row class="mx-sm-6">
          <v-col cols="12" class="d-flex flex-column">
            <div class="d-flex align-center justify-space-between">
              <div class="d-flex align-center">
                <span class="text-h6 secondary--text">{{
                  prevencion?.codigo_prevencion
                }}</span>
                <v-icon color="secondary" class="ml-4">mdi-alert</v-icon>
              </div>
              <v-btn
                v-if="haveRole('ROLE_DIRECCION_COMPRAS') && habilitarAuditoria"
                class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
                color="red"
                outlined
                @click="changeStatusPrev(5)"
              >
                Marcar para auditoria
              </v-btn>
            </div>
            <span class="mt-2" v-if="haveRole('ROLE_UACI')">
              Una vez enviada, la presente será revisada por el Director de la
              DINAC. Manténgase pendiente del seguimiento
            </span>
          </v-col>
          <v-col cols="12">
            <v-textarea
              label="Motivo"
              placeholder="En este proceso se debe tener especial cuidado por..."
              outlined
              disabled
              v-model="prevencion.prevencion"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-file-input
              outlined
              required
              disabled
              v-model="doc"
              prepend-icon=""
              accept="application/pdf"
              prepend-inner-icon="mdi-paperclip"
              label="Documento adjunto"
            >
              <template v-slot:append>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      @click="descargarDoc(prevencion.documento)"
                      color="green"
                      >mdi-file-download</v-icon
                    >
                  </template>
                  <span>Descargar archivo</span>
                </v-tooltip>
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="menuFechaVencimiento"
              v-model="menuFechaVencimiento"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              disabled
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="fechaFormatted"
                  label="Fecha de vencimiento"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  disabled
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                title="es"
                v-model="prevencion.fecha_vencimiento"
                locale="es-Es"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            v-if="
              haveRole('ROLE_UACI') &&
              (prevencion.id_estado_prevencion == 2 ||
                prevencion.id_estado_prevencion == 3)
            "
          >
            <p class="text-h6 secondary--text">Aclaración</p>
            <v-textarea
              label="Aclaración * "
              outlined
              v-model="seguimientoForm.resolucion"
              @input="$v.seguimientoForm.resolucion.$touch()"
              :error-messages="seguimientoErrors"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-if="
              haveRole('ROLE_UACI') &&
              (prevencion.id_estado_prevencion == 2 ||
                prevencion.id_estado_prevencion == 3)
            "
          >
            <v-file-input
              outlined
              required
              v-model="seguimientoForm.documento"
              prepend-icon=""
              accept="application/pdf"
              prepend-inner-icon="mdi-paperclip"
              label="Documento adjunto(opcional)"
            >
            </v-file-input>
          </v-col>
          <v-col cols="12" v-if="aclaracionesList?.length > 0">
            <p class="text-h6 secondary--text">Seguimiento aclaraciones</p>

            <v-timeline dense clipped>
              <v-timeline-item
                v-for="(seguimiento, i) in aclaracionesList"
                color="secondary"
                small
                :key="i"
              >
                <v-row justify="space-between align-center">
                  <v-col cols="7">
                    <p>{{ seguimiento.resolucion }}</p>
                    <v-btn
                      v-if="seguimiento.documento"
                      small
                      outlined
                      @click="descargarDoc(seguimiento.documento)"
                      color="green"
                    >
                      descargar documento
                    </v-btn>
                  </v-col>
                  <v-col class="text-right" cols="5">
                    {{
                      moment(seguimiento.created_at).format(
                        "DD/MM/YYYY hh:mm a"
                      )
                    }}
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-col>
          <v-col
            cols="12"
            v-if="
              haveRole('ROLE_DIRECCION_COMPRAS') &&
              prevencion.id_estado_prevencion == 5
            "
          >
            <p class="text-h6 secondary--text">Auditoria</p>
            <v-textarea
              label="Auditoria * "
              outlined
              v-model="seguimientoForm.resolucion"
              @input="$v.seguimientoForm.resolucion.$touch()"
              :error-messages="seguimientoErrors"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-if="
              haveRole('ROLE_DIRECCION_COMPRAS') &&
              prevencion.id_estado_prevencion == 5
            "
          >
            <v-file-input
              outlined
              required
              v-model="seguimientoForm.documento"
              prepend-icon=""
              accept="application/pdf"
              prepend-inner-icon="mdi-paperclip"
              label="Documento adjunto(opcional)"
            >
            </v-file-input>
          </v-col>
          <v-col cols="12" v-if="auditoriasList?.length > 0">
            <p class="text-h6 secondary--text">Auditorias</p>

            <v-timeline dense clipped>
              <v-timeline-item
                v-for="(seguimiento, i) in auditoriasList"
                color="secondary"
                small
                :key="i"
              >
                <v-row justify="space-between">
                  <v-col cols="7">
                    <p>{{ seguimiento.resolucion }}</p>
                    <v-btn
                      v-if="seguimiento.documento"
                      small
                      outlined
                      @click="descargarDoc(seguimiento.documento)"
                      color="green"
                    >
                      descargar documento
                    </v-btn>
                  </v-col>
                  <v-col class="text-right" cols="5">
                    {{
                      moment(seguimiento.created_at).format(
                        "DD/MM/YYYY hh:mm a"
                      )
                    }}
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>

        <!-- acciones -->
        <v-row cols="12" class="d-flex flex-column flex-sm-row mx-sm-4 mb-6">
          <v-btn
            class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            outlined
            @click="CloseModalPrev"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="red"
            outlined
            v-if="
              haveRole('ROLE_DIRECCION_COMPRAS') &&
              prevencion.id_estado_prevencion == 1
            "
            @click="changeStatusPrev(7)"
          >
            Rechazar
          </v-btn>
          <v-btn
            class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="green"
            outlined
            v-if="
              haveRole('ROLE_DIRECCION_COMPRAS') &&
              prevencion.id_estado_prevencion == 1
            "
            @click="changeStatusPrev(2)"
          >
            Aprobar
          </v-btn>
          <v-btn
            class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            v-if="
              (haveRole('ROLE_UACI') && prevencion.id_estado_prevencion == 2) ||
              (haveRole('ROLE_DIRECCION_COMPRAS') &&
                prevencion.id_estado_prevencion == 5)
            "
            @click="saveSeguimiento()"
          >
            Aceptar
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
import { required, helpers } from "vuelidate/lib/validators";

const letter = helpers.regex("letter", /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 -]*$/);

export default {
  name: "PrevencionComponent",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    prevencion: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    menuFechaVencimiento: false,
    loadingPrev: false,
    seguimientoForm: {
      id_prevencion: null,
      id_proceso_compra: null,
      resolucion: null,
      documento: null,
    },
    doc: null,
  }),
  validations: {
    seguimientoForm: {
      resolucion: { required, letter },
    },
  },
  computed: {
    habilitarAuditoria() {
      return (
        moment(this.prevencion.fecha_vencimiento).format("YYYY-MM-DD") <
          moment().format("YYYY-MM-DD") &&
        (this.prevencion.id_estado_prevencion == 2 ||
          this.prevencion.id_estado_prevencion == 3)
      );
    },
    fechaFormatted() {
      return this.prevencion.fecha_vencimiento
        ? moment(this.prevencion.fecha_vencimiento).format("DD/MM/YYYY")
        : "";
    },
    aclaracionesList() {
      return this.prevencion?.SegPrevProcesos?.filter(
        (target) => target.id_tipo_seguimiento == 1
      );
    },
    auditoriasList() {
      return this.prevencion?.SegPrevProcesos?.filter(
        (target) => target.id_tipo_seguimiento == 2
      );
    },
    seguimientoErrors() {
      const errors = [];
      if (!this.$v.seguimientoForm.resolucion.$dirty) return errors;
      !this.$v.seguimientoForm.resolucion.required &&
        errors.push("Campo requerido");
      !this.$v.seguimientoForm.resolucion.letter &&
        errors.push("El  campo no puede contener caracteres especiales");
      return errors;
    },
  },
  methods: {
    CloseModalPrev() {
      this.$emit("closeModal");
      Object.keys(this.seguimientoForm).forEach((key) => {
        this.seguimientoForm[key] = null;
      });
      this.$v.seguimientoForm.$reset();
    },
    async descargarDoc(ruta) {

      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: ruta,
        })
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], "Documento", {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.setAttribute("download", "Documento");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },
    async saveSeguimiento() {
      this.$v.seguimientoForm.$touch();
      if (!this.$v.seguimientoForm.$invalid) {
        this.seguimientoForm.id_prevencion = this.prevencion.id;
        this.seguimientoForm.id_proceso_compra =
          this.prevencion.id_proceso_compra;

        let formData = new FormData();
        Object.keys(this.seguimientoForm).forEach((key) => {
          if (this.seguimientoForm[key])
            formData.append(key, this.seguimientoForm[key]);
        });

        const { status } =
          await this.services.PrevencionesServices.addSeguimientoPrevencion(
            this.prevencion.id,
            formData
          );
        if (status == 201) {
          this.temporalAlert({
            message: "Aclaración agregada con éxito",
            show: true,
            type: "success",
          });
          this.CloseModalPrev();
          this.$emit("reload");
        }
      }
    },
    async changeStatusPrev(id_estado) {
      const { status } =
        await this.services.PrevencionesServices.cambiarEstadoPrevencion(
          this.prevencion.id,
          id_estado
        );

      if (status == 200) {
        this.temporalAlert({
          message: "Aclaración agregada con éxito",
          show: true,
          type: "success",
        });
        this.CloseModalPrev();
        this.$emit("reload");
      }
    },
  },
};
</script>
