<template>
  <section>
    <p class="text-h6 secondary--text">Prevenciones</p>
    <!--Filtros-->
    <v-row>
      <!-- <v-col cols="12" sm="12" md="4">
        <v-text-field
          label="Nombre del proceso"
          outlined
          hide-details
          v-model="filters.nombre"
        />
      </v-col> -->
      <v-col cols="12" sm="12" md="4">
        <v-select
          label="Estado"
          :items="ctlEstados"
          item-value="id"
          item-text="nombre"
          outlined
          hide-details
          v-model="filters.estado"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-select
          label="Institución"
          :items="ctlInstituciones"
          item-value="id"
          item-text="nombre"
          outlined
          hide-details
          v-model="filters.institucion"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-menu
          ref="menuDataRange"
          v-model="menuFecha"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              v-model="dateFormatted"
              label="Fecha creación"
              prepend-inner-icon="mdi-calendar"
              readonly
              hide-details
              v-bind="attrs"
              v-on="on"
              @click:clear=""
            />
          </template>
          <v-date-picker
            title="es"
            v-model="filters.fecha_creacion"
            locale="es-Es"
          >
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="12" md="4">
        <div class="d-flex align-center justify-space-around">
          <v-col>
            <v-btn
              @click="getListadoPrevenciones()"
              dark
              block
              color="secondary"
              >Buscar</v-btn
            >
          </v-col>
          <v-col>
            <v-btn outlined block color="secondary" @click="clearFiltros()"
              >Limpiar</v-btn
            >
          </v-col>
        </div>
      </v-col>
    </v-row>
    <p class="mb-0 mt-8">Listado de prevenciones</p>
    <data-table-component
      v-models:select="perPage"
      v-models:pagina="page"
      :headers="headers"
      :items="items"
      @paginar="paginate"
      :total_registros="totalRows"
    >
      <template v-slot:[`item.estado`]="{ item }">
        <v-chip label dark :color="item.EstadoPrevProceso?.color">
          {{ item.EstadoPrevProceso?.nombre || "" }}
        </v-chip>
      </template>
      <template v-slot:[`item.institucion`]="{ item }">
        {{ item.ProcesoCompra?.Institucion?.nombre }}
      </template>
      <template v-slot:[`item.proceso`]="{ item }">
        {{ item.ProcesoCompra?.nombre_proceso }}
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="item?.prevO?.Empleado?.Usuario?.id == userInfo?.user?.id"
              icon
              v-bind="attrs"
              v-on="on"
              @click="getPrevencion(item)"
            >
              <v-icon color="secondary"> mdi-cog </v-icon>
            </v-btn>
          </template>
          <span> Agregar seguimiento</span>
        </v-tooltip>
      </template>
    </data-table-component>
    <PrevencionComponent
      @closeModal="closeModal"
      @reload="getListadoPrevenciones()"
      :showModal="showModalPrev"
      :prevencion="prevencion"
    />
  </section>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import PrevencionComponent from "./components/PrevencionComponent.vue";
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "ListaPrevencionView",
  components: {
    DataTableComponent,
    PrevencionComponent,
  },
  data: () => ({
    filters: {
      institucion: null,
      fecha_creacion: null,
      estado: null,
      nombre: null,
    },
    menuFecha: false,
    headers: [
      {
        text: "Código",
        value: "codigo_prevencion",
        align: "center",
      },
      {
        text: "Prevención",
        value: "prevencion",
      },
      {
        text: "Nombre del proceso",
        value: "proceso",
      },
      {
        text: "Institución",
        value: "institucion",
      },
      {
        text: "Estado",
        value: "estado",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    items: [],
    totalRows: 0,
    page: 1,
    perPage: 10,
    ctlInstituciones: [],
    ctlEstados: [],
    loadingPrev: false,
    showModalPrev: false,
    prevencion: {},
  }),
  computed: {
    dateFormatted() {
      return this.filters.fecha_creacion
        ? moment(this.filters.fecha_creacion).format("DD/MM/YYYY")
        : "";
    },
    ...mapState(["userInfo"])
  },
  methods: {
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;
      this.getListadoPrevenciones();
      //   this.obtainListProcesos();
    },
    clearFiltros() {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = null;
      });
      this.getListadoPrevenciones();
    },
    async getListadoPrevenciones() {


      const { status, data, headers } =
        await this.services.PrevencionesServices.getPrevenciones({
          ...this.filters,
          page: this.page,
          per_page: this.perPage,
        })

      if (status == 200) {
        this.items = data;
        this.totalRows = Number(headers.total_rows);
      }

    },
    async fetchInstituciones() {
      const response =
        await this.services.Instituciones.getCatalogoInstituciones({
          pagination: false,
        });
      if (response) {
        this.ctlInstituciones = response.data;
      }
    },
    async fetchEstados() {
      const response =
        await this.services.PrevencionesServices.getEstadosPrevencion();
      if (response) {
        this.ctlEstados = response.data;
      }
    },
    async getPrevencion(item) {
      this.loadingPrev = true;
      const { status, data } =
        await this.services.PrevencionesServices.getPrevencion(item.id).catch(
          () => (this.loadingPrev = false)
        );
      if (status == 200) {
        this.showModalPrev = true;
        this.prevencion = data;
      }
      this.loadingPrev = false;
    },
    closeModal() {
      this.showModalPrev = false;
      this.prevencion = {};
    },
  },
  created() {
    this.getListadoPrevenciones();
    this.fetchInstituciones();
    this.fetchEstados();
  },
};
</script>
